import { interopDefault } from '~/utils';
import RouteEnum from '~/enums/RouteEnum';

export default [
  {
    path: '/organizations',
    name: RouteEnum.ORGANIZATIONS.INDEX,
    component: () =>
      interopDefault(
        import(
          /* webpackChunkName: 'admin:organizations.index' */ '~/pages/admin/organizations/index.vue'
        ),
      ),
  },
  {
    path: '/organizations/create',
    name: RouteEnum.ORGANIZATIONS.CREATE,
    component: () =>
      interopDefault(
        import(
          /* webpackChunkName: 'admin:organizations.create' */ '~/pages/admin/organizations/create.vue'
        ),
      ),
  },
  {
    path: '/organizations/:id',
    component: () =>
      interopDefault(
        import(
          /* webpackChunkName: 'employee:organization' */ '~/pages/admin/organizations/show.vue'
        ),
      ),
    children: [
      {
        path: '',
        name: RouteEnum.ORGANIZATION.DETAILS,
        component: () =>
          interopDefault(
            import(
              /* webpackChunkName: 'admin:organization.details' */ '~/pages/admin/organizations/details.vue'
            ),
          ),
      },
      {
        path: 'profiles',
        name: RouteEnum.ORGANIZATION.PROFILES,
        component: () =>
          interopDefault(
            import(
              /* webpackChunkName: 'admin:organization.profiles' */ '~/pages/admin/organizations/profiles/index.vue'
            ),
          ),
      },
      {
        path: 'profiles/create',
        name: RouteEnum.PROFILES.CREATE,
        component: () =>
          interopDefault(
            import(
              /* webpackChunkName: 'admin:profiles.edit-create' */ '~/pages/admin/organizations/profiles/edit-create.vue'
            ),
          ),
      },
      {
        path: 'profiles/:profile_id/edit',
        name: RouteEnum.PROFILES.EDIT,
        component: () =>
          interopDefault(
            import(
              /* webpackChunkName: 'admin:profiles.edit-create' */ '~/pages/admin/organizations/profiles/edit-create.vue'
            ),
          ),
      },
      {
        path: 'employees',
        name: RouteEnum.ORGANIZATION.EMPLOYEES,
        component: () =>
          interopDefault(
            import(
              /* webpackChunkName: 'admin:organization.employees' */ '~/pages/admin/organizations/employees/index.vue'
            ),
          ),
      },
      {
        path: 'invitations',
        name: RouteEnum.ORGANIZATION.INVITATIONS,
        component: () =>
          interopDefault(
            import(
              /* webpackChunkName: 'admin:organization.invitations' */ '~/pages/admin/organizations/invitations/index.vue'
            ),
          ),
      },
      {
        path: 'registration-codes',
        name: RouteEnum.ORGANIZATION.REGISTRATION_CODES,
        component: () =>
          interopDefault(
            import(
              /* webpackChunkName: 'admin:organization.registration-codes' */ '~/pages/admin/organizations/registration-codes/index.vue'
            ),
          ),
      },
      {
        path: 'registration-codes/create',
        name: RouteEnum.REGISTRATION_CODES.CREATE,
        component: () =>
          interopDefault(
            import(
              /* webpackChunkName: 'admin:organization.registration-codes.edit-create' */ '~/pages/admin/organizations/registration-codes/edit-create.vue'
            ),
          ),
      },
      {
        path: 'registration-codes/:registration_code_id/edit',
        name: RouteEnum.REGISTRATION_CODES.EDIT,
        component: () =>
          interopDefault(
            import(
              /* webpackChunkName: 'admin:organization.registration-codes.edit-create' */ '~/pages/admin/organizations/registration-codes/edit-create.vue'
            ),
          ),
      },
      {
        path: 'email-templates',
        name: RouteEnum.ORGANIZATION.EMAIL_TEMPLATES,
        component: () =>
          interopDefault(
            import(
              /* webpackChunkName: 'admin:organization.email-templates' */ '~/pages/admin/organizations/email-templates/index.vue'
            ),
          ),
      },
      {
        path: 'email-templates/create',
        name: RouteEnum.EMAIL_TEMPLATES.CREATE,
        component: () =>
          interopDefault(
            import(
              /* webpackChunkName: 'admin:organization.email-templates.edit-create' */ '~/pages/admin/organizations/email-templates/edit-create.vue'
            ),
          ),
      },
      {
        path: 'email-templates/:email_template_id/edit',
        name: RouteEnum.EMAIL_TEMPLATES.EDIT,
        component: () =>
          interopDefault(
            import(
              /* webpackChunkName: 'admin:organization.email-templates.edit-create' */ '~/pages/admin/organizations/email-templates/edit-create.vue'
            ),
          ),
      },
      {
        path: 'alerts',
        name: RouteEnum.ORGANIZATION.ALERTS,
        component: () =>
          interopDefault(
            import(
              /* webpackChunkName: 'admin:organization.alerts.index' */ '~/pages/admin/organizations/alerts/index.vue'
            ),
          ),
      },
      {
        path: 'alerts/create',
        name: RouteEnum.ALERTS.CREATE,
        component: () =>
          interopDefault(
            import(
              /* webpackChunkName: 'admin:organization.alerts/edit-create' */ '~/pages/admin/organizations/alerts/edit-create.vue'
            ),
          ),
      },
      {
        path: 'alerts/:alert_id/edit',
        name: RouteEnum.ALERTS.EDIT,
        component: () =>
          interopDefault(
            import(
              /* webpackChunkName: 'admin:organization.alerts/edit-create' */ '~/pages/admin/organizations/alerts/edit-create.vue'
            ),
          ),
      },
      {
        path: 'pings',
        name: RouteEnum.ORGANIZATION.PINGS,
        component: () =>
          interopDefault(
            import(
              /* webpackChunkName: 'admin:organization.pings.index' */ '~/pages/admin/organizations/pings/index.vue'
            ),
          ),
      },
      {
        path: 'delete',
        name: RouteEnum.ORGANIZATION.DELETE,
        component: () =>
          interopDefault(
            import(
              /* webpackChunkName: 'admin:organization.delete' */ '~/pages/admin/organizations/delete/index.vue'
            ),
          ),
      },
    ],
  },
  {
    path: '/organizations-progress',
    name: RouteEnum.ORGANIZATIONS_PROGRESS.INDEX,
    component: () =>
      interopDefault(
        import(
          /* webpackChunkName: 'admin:organizations-progress.index' */ '~/pages/admin/organizations-progress/index.vue'
        ),
      ),
  },
];
