export default async function fetchMe({ $auth, route, $RouteEnum, redirect }) {
  const routeRequiresNoAuth = route.meta.some(e => e.requiresNoAuth);
  if (!$auth.loggedIn && !routeRequiresNoAuth) {
    redirect({
      name: $RouteEnum.LOGIN,
      query: {
        to: route.fullPath,
      },
    });
  }

  if ($auth.loggedIn && !$auth.me) {
    try {
      const me = await $auth.fetchMe();
      $auth.commit('SET_ME', me);
    } catch (e) {
      this.$axios.handleError(e);
    }
  }
}
