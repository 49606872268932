class RouteEnum {
  // AUTH.
  static LOGIN = 'login';
  static LOGOUT = 'logout';
  static FORGOT_PASSWORD = 'forgot-password';
  static RESET_PASSWORD = 'reset-password';
  static REGISTER_APPLICANT = 'register-applicant';
  static REGISTER_EMPLOYEE = 'register-employee';
  static VERIFY_EMAIL = 'verify-email';

  // SHARED.
  static DASHBOARD = 'dashboard';

  static ACCOUNT = {
    GENERAL_INFORMATION: 'account',
    SECURITY: 'account.security',
    DANGER_ZONE: 'account.danger-zone',
  };

  static HELP = 'help';
  static NEAR_BY = 'near-by';

  // APPLICANT ONLY.
  static TOS = {
    ACCEPT: 'tos.accept',
    VERIFY: 'tos.verify',
  };

  static APPLICANT_ONBOARDING = 'onboarding';
  static EMPLOYEE_ONBOARDING = 'onboarding';

  static APPLICANT_RENEW_LOOKING = 'applicants.renew-looking';

  static ORGANIZATION = {
    DETAILS: 'organization',
    PROFILES: 'organization.profiles',
    EMPLOYEES: 'organization.employees',
    INVITATIONS: 'organization.invitations',
    REGISTRATION_CODES: 'organization.registration-codes',
    EMAIL_TEMPLATES: 'organization.email-templates',
    ALERTS: 'organization.alerts',
    PINGS: 'organization.pings',
    DELETE: 'organization.delete',
  };

  static APPLICANTS = {
    INDEX: 'applicants.index',
    SHOW: 'applicants.show',
  };

  static ORGANIZATIONS = {
    INDEX: 'organizations.index',
    CREATE: 'organizations.create',
    EDIT: 'organizations.edit',
  };

  static ORGANIZATIONS_PROGRESS = {
    INDEX: 'organizations-progress.index',
  };

  static TENANTS = {
    INDEX: 'tenants.index',
    SHOW: 'tenants.show',
  };

  static EMPLOYEES = {
    INDEX: 'employees.index',
    SHOW: 'employees.show',
  };

  static ALERTS = {
    INDEX: 'alerts.index',
    SHOW: 'alerts.show',
    EDIT: 'alerts.edit',
    CREATE: 'alerts.create',
  };

  static PROFILES = {
    INDEX: 'profiles.index',
    SHOW: 'profiles.show',
    EDIT: 'profile.edit',
    CREATE: 'profile.create',
  };

  static EMAIL_TEMPLATES = {
    INDEX: 'email-templates.index',
    SHOW: 'email-templates.show',
    EDIT: 'email-templates.edit',
    CREATE: 'email-templates.create',
  };

  static REGISTRATION_CODES = {
    INDEX: 'registration-codes.index',
    SHOW: 'registration-codes.show',
    EDIT: 'registration-codes.edit',
    CREATE: 'registration-codes.create',
  };

  static SEARCH = 'search';

  static PINGS = 'pings';
}

export default RouteEnum;
