import { interopDefault } from '~/utils';
import RouteEnum from '~/enums/RouteEnum';
import alerts from '~/routes/employee/alerts';
import profiles from '~/routes/employee/profiles';
import registrationCodes from '~/routes/employee/registration-codes';
import emailTemplates from '~/routes/employee/email-templates';
import organization from '~/routes/employee/organization';
import search from '~/routes/employee/search';

export default () => [
  {
    path: '/',
    redirect: '/dashboard',
  },
  {
    path: '/dashboard',
    name: RouteEnum.DASHBOARD,
    component: () =>
      interopDefault(
        import(/* webpackChunkName: 'employee:dashboard' */ '~/pages/employee/dashboard/show.vue'),
      ),
  },
  ...organization,
  ...search,
  ...alerts,
  ...profiles,
  ...registrationCodes,
  ...emailTemplates,
  {
    path: '/applicants',
    name: RouteEnum.APPLICANTS.INDEX,
    component: () =>
      interopDefault(
        import(
          /* webpackChunkName: 'employee:applicants.index' */ '~/pages/employee/applicants/index.vue'
        ),
      ),
  },
  {
    path: '/pings',
    name: RouteEnum.PINGS,
    component: () =>
      interopDefault(
        import(
          /* webpackChunkName: 'employee:pings' */ '~/pages/employee/pings/index.vue'
        ),
      ),
  },
  {
    path: '/onboarding',
    name: RouteEnum.EMPLOYEE_ONBOARDING,
    component: () =>
      interopDefault(
        import(
          /* webpackChunkName: 'employee:onboarding' */ '~/pages/employee/flows/onboarding/onboarding.vue'
        ),
      ),
  },
  {
    path: '/help',
    name: RouteEnum.HELP,
    component: () =>
      interopDefault(import(/* webpackChunkName: 'employee:help' */ '~/pages/employee/help.vue')),
  },
];
