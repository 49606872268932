<template>
  <t-document standalone>
    <navigation :items="items" />
    <!-- TODO: remove -->
    <lang-switcher />
    <nuxt />
    <portal-target name="modals" multiple />
    <notifications />
  </t-document>
</template>
<script>
import Notifications from '~/layouts/partials/Notifications';
import UserTypeEnum from '~/enums/UserTypeEnum';
import Navigation from '~/layouts/partials/Navigation';
import LangSwitcher from '~/components/LangSwitcher';

export default {
  components: { Navigation, Notifications, LangSwitcher },

  middleware: ['auth', 'verified', 'onboarded', 'tos-accepted'],

  computed: {
    items() {
      return this.userTypeSpecificItems.concat(this.sharedItems);
    },

    userTypeSpecificItems() {
      const { userType } = this.$auth;
      if (userType === UserTypeEnum.APPLICANT) {
        return this.applicantItems;
      }

      if (userType === UserTypeEnum.EMPLOYEE) {
        return this.employeeItems;
      }

      if (userType === UserTypeEnum.TENANT_MEMBER) {
        return this.tenantMemberItems;
      }

      if (userType === UserTypeEnum.ADMIN) {
        return this.adminItems;
      }

      return [];
    },

    sharedItems() {
      return [
        {
          label: this.$t('fields.account'),
          icon: 'user-edit',
          routeName: this.$RouteEnum.ACCOUNT.GENERAL_INFORMATION,
        },
        {
          label: this.$t('fields.help'),
          icon: 'info-circle',
          routeName: this.$RouteEnum.HELP,
        },
      ];
    },

    applicantItems() {
      return [
        {
          label: this.$t('fields.dashboard'),
          icon: 'home',
          routeName: this.$RouteEnum.DASHBOARD,
        },
        {
          label: this.$t('fields.near_by'),
          icon: 'search',
          routeName: this.$RouteEnum.NEAR_BY,
        },
      ];
    },

    employeeItems() {
      return [
        {
          label: this.$t('fields.dashboard'),
          icon: 'home',
          routeName: this.$RouteEnum.DASHBOARD,
        },
        {
          label: this.$t('fields.profiles'),
          icon: ['far', 'folder'],
          routeName: this.$RouteEnum.PROFILES.INDEX,
        },
        {
          label: this.$t('fields.email_templates'),
          icon: 'envelope',
          routeName: this.$RouteEnum.EMAIL_TEMPLATES.INDEX,
        },
        {
          label: this.$t('fields.search'),
          icon: 'search',
          routeName: this.$RouteEnum.SEARCH,
        },
        {
          label: this.$t('fields.alerts'),
          icon: ['far', 'bell'],
          routeName: this.$RouteEnum.ALERTS.INDEX,
        },
        {
          label: this.$t('fields.registration_codes'),
          icon: 'key',
          routeName: this.$RouteEnum.REGISTRATION_CODES.INDEX,
        },
        {
          label: this.$t('fields.recommended_applicants'),
          icon: 'graduation-cap',
          routeName: this.$RouteEnum.APPLICANTS.INDEX,
        },
        {
          label: this.$t('fields.contacted_applicants'),
          icon: 'envelope',
          routeName: this.$RouteEnum.PINGS,
        },
        {
          label: this.$t('fields.organization'),
          icon: 'building',
          routeName: this.$RouteEnum.ORGANIZATION.DETAILS,
        },
      ];
    },

    tenantMemberItems() {
      return [
        {
          label: this.$t('fields.dashboard'),
          icon: 'home',
          routeName: this.$RouteEnum.DASHBOARD,
        },
        {
          label: this.$t('fields.applicants'),
          icon: 'graduation-cap',
          routeName: this.$RouteEnum.APPLICANTS.INDEX,
        },
        {
          label: this.$t('fields.organizations'),
          icon: 'building',
          routeName: this.$RouteEnum.ORGANIZATIONS.INDEX,
        },
        {
          label: this.$t('fields.organizations_progress'),
          icon: 'tachometer-alt',
          routeName: this.$RouteEnum.ORGANIZATIONS_PROGRESS.INDEX,
        },
        {
          label: this.$t('fields.employees'),
          icon: 'hard-hat',
          routeName: this.$RouteEnum.EMPLOYEES.INDEX,
        },
      ];
    },

    adminItems() {
      return [
        {
          label: this.$t('fields.dashboard'),
          icon: 'home',
          routeName: this.$RouteEnum.DASHBOARD,
        },
        {
          label: this.$t('fields.applicants'),
          icon: 'graduation-cap',
          routeName: this.$RouteEnum.APPLICANTS.INDEX,
        },
        {
          label: this.$t('fields.organizations'),
          icon: 'building',
          routeName: this.$RouteEnum.ORGANIZATIONS.INDEX,
        },
        {
          label: this.$t('fields.organizations_progress'),
          icon: 'tachometer-alt',
          routeName: this.$RouteEnum.ORGANIZATIONS_PROGRESS.INDEX,
        },
        {
          label: this.$t('fields.employees'),
          icon: 'hard-hat',
          routeName: this.$RouteEnum.EMPLOYEES.INDEX,
        },
        {
          label: this.$t('fields.tenants'),
          icon: 'draw-polygon',
          routeName: this.$RouteEnum.TENANTS.INDEX,
        },
      ];
    },
  },
};
</script>
